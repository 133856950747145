import React from 'react';

const About = () => {
    return (
        <div id="about">
            <h3>About Me</h3>
            <div className="about-head">
                Here you will find more information about me, what I do, and my current skills mostly in terms of programming and technology. 📚💻🌐
            </div>

            <div className="about-container">
                <div className="about-left">
                    <div className="left-content">
                        <h4>My Journey through lines of code!</h4>
                        <p>
                            Hey there! I'm Digvijay Kadam, your go-to Full-Stack Developer, ready to turn your wildest ideas into stunning reality. 🚀
                            <br /><br />
                            I'm a Final-year student with an insatiable hunger for all things tech. Whether it's crafting elegant front-end designs or architecting powerful back-end solutions, I'm on a mission to make a mark in the digital realm.
                            <br /><br />
                            Passionate about learning and problem-solving, I'm always seeking out new challenges and ways to push the boundaries of what's possible. My heart lies in creating beautifully simple yet impactful projects that resonate with users.🌟
                        </p>
                    </div>
                </div>

                <div className="about-right">
                    <div className="right-content">
                        <h4>My Skills</h4>
                        <div>
                            <span>HTML</span>
                            <span>CSS</span>
                            <span>JavaScript</span>
                            <span>React</span>
                            <span>Node.js</span>
                            <span>ExpressJS</span>
                            <span>MongoDB</span>
                            <span>Responsive Design</span>
                            <span>GIT</span>
                            <span>Github</span>
                            <span>Docker</span>
                            <span>Bootstrap</span>
                            <span>Figma</span>
                            <span>Canva</span>
                            <span>Java</span>
                            <span>Python(Basic)</span>
                            <span>DSA</span>
                            <span>Design</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
