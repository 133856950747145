import React from 'react'

const Experience = () => {
    return (
        <div id="experience">
            <h3>Work Experience</h3>

            <div className="about-container" style={{padding:"50px 0"}}>


                <div className="about-right">
                    <div className="right-content">
                       <img src="./assets/coding.svg" alt="" />
                    </div>
                </div>
                <div className="about-left">
                    <div className="left-content">
                        <h4>Internship Experience</h4>
                        <p>
                            <span style={{ color: "white" }}>Bluecrest Software</span>   |<span style={{ color: "white" }}> Software Developer Intern </span>
                            <br /> <span style={{ fontSize: "16px", color: "skyblue" }}>(Nov 2023 - March 2024)</span>
                            <br />
                            - Led UI component development with React.js
                            - Streamlined API integration and optimized codebase
                            - Reduced bug resolution time by 30%, enhancing customer satisfaction
                            <br /><br />
                            <span style={{ color: "white" }}>Pusht Health Service Pvt Ltd</span>   |<span style={{ color: "white" }}> Web Development Intern </span>
                            <br />
                            <span style={{ fontSize: "16px", color: "skyblue" }}>(July 2023 - Aug 2023)</span>

                            <br />
                            - Designed high-converting landing pages
                            - Boosted LinkedIn engagement by 40% and improved lead conversion rates

                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Experience
